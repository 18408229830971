<template>
  <div>
    <div>
      <div class="txt-14 txt-font-din-medium">{{ title }}</div>
      <div class="txt-12 txt-grey">({{ subtext }})</div>
    </div>
    <table class="w75 mt-10">
      <tr v-if="all">
        <td>{{ $t('components.labels.all') }}</td>
        <td>({{ all != null ? all.n : '' }})</td>
        <td>({{ all != null ? all.x : '' }})</td>
        <td>{{ all != null ? (all.prop * 100).toFixed(1) : '' }}%</td>
        <td>
          <span v-if="!isExhaustive">
            ({{ all != null ? (all.prop_low * 100).toFixed(1) : '' }} -
            {{ all != null ? (all.prop_upp * 100).toFixed(1) : '' }} {{ $t('values.ci', { number: '95%' }) }})
          </span>
        </td>
      </tr>
      <tr v-if="boys">
        <td>{{ $tc('components.labels.boys', 2) }}</td>
        <td>({{ boys != null ? boys.n : '' }})</td>
        <td>({{ boys != null ? boys.x : '' }})</td>
        <td>{{ boys != null ? (boys.prop * 100).toFixed(1) : '' }}%</td>
        <td>
          <span v-if="!isExhaustive">
            ({{ boys != null ? (boys.prop_low * 100).toFixed(1) : '' }} -
            {{ boys != null ? (boys.prop_upp * 100).toFixed(1) : '' }} {{ $t('values.ci', { number: '95%' }) }})
          </span>
        </td>
      </tr>
      <tr v-if="girls">
        <td>{{ $tc('components.labels.girls', 2) }}</td>
        <td>({{ girls != null ? girls.n : '' }})</td>
        <td>({{ girls != null ? girls.x : '' }})</td>
        <td>{{ girls != null ? (girls.prop * 100).toFixed(1) : '' }}%</td>
        <td>
          <span v-if="!isExhaustive">
            ({{ girls != null ? (girls.prop_low * 100).toFixed(1) : '' }} -
            {{ girls != null ? (girls.prop_upp * 100).toFixed(1) : '' }} {{ $t('values.ci', { number: '95%' }) }})
          </span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'AnthropometryResultsItem',
  props: {
    title: {
      type: String,
      required: true
    },
    subtext: {
      type: String,
      required: true
    },
    prevalence: {
      type: Array,
      default: undefined
    },
    surveyInfo: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    isExhaustive() {
      return this.surveyInfo && this.surveyInfo.metadata && this.surveyInfo.metadata.samplingMethod === 'Exhaustive';
    },
    all() {
      return this.prevalence ? this.prevalence.find(item => item.level === 'All') : null;
    },
    girls() {
      return this.prevalence ? this.prevalence.find(item => item.level === 'f') : null;
    },
    boys() {
      return this.prevalence ? this.prevalence.find(item => item.level === 'm') : null;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
